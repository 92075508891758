<template>
	<div class="exception-wrap">
		<img src="../../../assets/images/exception/403.png" />
		<p class="help-info">无访问权限</p>
		<router-link to="/">返回首页</router-link>
	</div>
</template>

<script>
export default {
	name: "Exception-403"
}
</script>

<style scoped></style>
